import React from 'react';
import { motion } from 'framer-motion';
import Header from './components/Header';
import ContactInfo from './components/ContactInfo';
import Experience from './components/Experience';
import Education from './components/Education';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Languages from './components/Languages';
import Courses from './components/Courses';
import Extracurricular from './components/Extracurricular';
import About from './components/About';
import './App.css';

function App() {
  return (
    <div className="App">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Header />
        <ContactInfo />
        <Experience />
        <Education />
        <Skills />
        <Projects />
        <Languages />
        <Courses />
        <Extracurricular />
        <About />
      </motion.div>
    </div>
  );
}

export default App;
