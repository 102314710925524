import React from 'react';

const Languages = () => (
  <section className="languages">
    <h3>LINGUAGENS</h3>
    <p>Português – Nativo</p>
    <p>Inglês – B1</p>
  </section>
);

export default Languages;
