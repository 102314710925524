import React from 'react';

const Education = () => (
  <section className="education">
    <h3>EDUCAÇÃO</h3>
    <p>FIAP — Graduação em Defesa Cibernética (Cursando)</p>
  </section>
);

export default Education;
