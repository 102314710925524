import React from 'react';
import { motion } from 'framer-motion';

const ContactInfo = () => (
  <div className="contact-info" style={{ textAlign: 'center' }}>
    <p>Conselheiro Lafaiete, Brasil</p>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <motion.a
        href="mailto:enzoaugusto001@gmail.com"
        style={{ textDecoration: 'none', color: 'inherit' }}
        whileHover={{ color: '#007BFF' }}
      >
        <p>enzoaugusto001@gmail.com</p>
      </motion.a>
    </motion.div>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <motion.a
        href="https://linkedin.com/in/enzoaugusto"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
        whileHover={{ color: '#007BFF' }}
      >
        <p>linkedin.com/in/enzoaugusto</p>
      </motion.a>
    </motion.div>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <motion.a
        href="https://github.com/nh3g"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
        whileHover={{ color: '#007BFF' }}
      >
        <p>github.com/nh3g</p>
      </motion.a>
    </motion.div>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <motion.a
        href="https://api.whatsapp.com/send?phone=5531986397240"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
        whileHover={{ color: '#007BFF' }}
      >
        <p>+55 31 9 8639 7240</p>
      </motion.a>
    </motion.div>
  </div>
);

export default ContactInfo;
