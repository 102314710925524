import React from 'react';

const Projects = () => (
  <section className="projects">
    <h3>PROJETOS</h3>
    <p>CadeWave: Plataforma de negociação de criptomoedas usando router pancakeswap vinculado a token com um sistema de recompensa com jogos e NFTs.</p>
    <p>InfraTools: Ferramenta capaz de integrar máquinas a infraestrutura pré-existente de maneira automatizada e com uma série de funções.</p>
  </section>
);

export default Projects;
