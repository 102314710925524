import React from 'react';

const Header = () => (
  <header>
    <h1>Enzo Augusto Ferreira Gonçalves</h1>
    <h2>Analista de Segurança / Pentester</h2>
  </header>
);

export default Header;
