import React from 'react';

const About = () => (
  <section className="about">
    <h3>SOBRE MIM</h3>
    <p>Sou um entusiasta de cibersegurança com um foco especial em Offensive Security. Minha jornada nessa área começou cedo, impulsionada por uma curiosidade insaciável por novas tecnologias e desafios complexos. Desde jovem, mergulhei no mundo da segurança da informação de forma autodidata, motivado pela minha paixão pela área.
Iniciei minha carreira atuando como HelpDesk de TI, onde desenvolvi habilidades essenciais em gestão e manutenção de sistemas. Nesse período, além de prestar suporte técnico, comecei a identificar e explorar maneiras de melhorar a segurança cibernética da empresa. Essa experiência prática me permitiu observar de perto as vulnerabilidades existentes e encontrar soluções proativas para mitigá-las, marcando o início de minha trajetória em segurança ofensiva.
Aos 16 anos, me destaquei como o colaborador mais jovem de uma empresa, onde fui responsável pela gestão de máquinas e usuários, exercitando um controle rigoroso e habilidades avançadas de gestão de acessos. No entanto, foi na Vero Internet que minha jornada por segurança ofensiva realmente começou. Além de gerenciar a infraestrutura de TI, detectei e relatei minhas primeiras vulnerabilidades de segurança, o que me rendeu o troféu "Mandou Bem". Esse reconhecimento destacou minhas habilidades em identificar, explorar e mitigar ameaças reais.
Minha experiência em Red Teaming inclui a realização de testes de invasão (pentesting) e o desenvolvimento de estratégias para simular ataques adversários, com o objetivo de fortalecer as defesas de uma organização. Através dessas atividades, adquiri uma compreensão profunda das técnicas, táticas e procedimentos (TTPs) utilizados por atacantes maliciosos, permitindo-me antecipar e neutralizar possíveis ameaças.
Além disso, minha incursão no mundo das criptomoedas e blockchain por hobby, me permitiu explorar novas fronteiras em gestão de projetos e desenvolvimento seguro, prestando consultoria para diversos projetos e desenvolvendo ferramentas especializadas, como um bot sniper conhecido no mercado.
Atualmente, estou focado em aprofundar meu conhecimento e habilidades em segurança ofensiva, buscando certificações como Pentester Profissional (DCPT) e uma graduação em Defesa Cibernética pela FIAP. Com uma dedicação contínua à aprendizagem e uma paixão por enfrentar desafios complexos, estou sempre em busca de oportunidades para contribuir de forma significativa para a área de Offensive Security e Red Teaming. 
</p>
    {/* Adicione mais detalhes sobre você aqui */}
  </section>
);

export default About;
