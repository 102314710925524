import React from 'react';
import { motion } from 'framer-motion';

const Extracurricular = () => (
  <section className="extracurricular">
    <h3>ATIVIDADES EXTRACURRICULARES</h3>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <ul>
        <li>Participação de palestras Roadsec BH - 2017 e 2019</li>
        <li>Administração de e-commerce</li>
        <li>Consultoria em peças de hardware</li>
        <li>Aproveitamento de 100% no programa Jovem Aprendiz desempenhando como o melhor da turma</li>
        <li>Montagem de computadores de alto desempenho junto a técnicas de Overclocking, onde consegui a marca de atingir o top 10 Brasil em configuração específica no programa 3DMARK</li>
        <li>Participação de filantropias vinculadas a ordem Demolay, como: Campanha do agasalho, campanha dia D, arrecadação ao asilo, entre outras</li>
        <li>Participação de CTFs e Desafios ethical hacker (HTB, HackingClub, OvertheWire, etc)</li>
      </ul>
    </motion.div>
  </section>
);

export default Extracurricular;
