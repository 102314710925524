import React from 'react';
import { motion } from 'framer-motion';

const Experience = () => (
  <section className="experience">
    <h3>EXPERIÊNCIAS</h3>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <h4>Analista DevOps Jr - Adapter</h4>
      <p>Conselheiro Lafaiete, Brasil | Ago 2022 – Jun 2023</p>
      <ul>
        <li>Administração interna da rede corporativa (disponibilidade, firewall, VPN, conectividade).</li>
        <li>Administração do Servidor Linux.</li>
        <li>Frente com novos clientes, para a instalação de infraestruturas.</li>
        <li>Automatização de novas implementações de servidores.</li>
        <li>Execução de Pentests.</li>
      </ul>
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <h4>Técnico de Infraestrutura Jr - Vero</h4>
      <p>Conselheiro Lafaiete, Brasil | Mar 2021 - Ago 2022</p>
      <ul>
        <li>Manutenção de software e hardware de infraestrutura.</li>
        <li>Supervisão de dois colaboradores.</li>
        <li>Scripting para automatização com shell/bash script.</li>
        <li>Administração de rede, switch e configuração de router tais como Cisco, Huawei, Mikrotik e Fortigate.</li>
        <li>Criação e implementação de procedimentos de segurança.</li>
      </ul>
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <h4>Analista Blockchain - Autônomo</h4>
      <p>Conselheiro Lafaiete, Brasil | Abr 2021 – Dez 2023</p>
      <ul>
        <li>Consultoria no desenvolvimento de projetos em diferentes redes, incluindo Binance Smart Chain, Fantom, Polygon, Kucoin, Avalanche e Harmony.</li>
        <li>Implementação e modificação de Smart Contracts.</li>
        <li>Criação de websites, jogos e design para novos projetos.</li>
        <li>Assistência ao desenvolvimento de robôs de sniper com TXs automáticos.</li>
      </ul>
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <h4>HelpDesk de TI - ViaReal</h4>
      <p>Conselheiro Lafaiete, Brasil | Jun 2019 - Out 2020</p>
      <ul>
        <li>Controle de inventário, logística para entrega de equipamentos e gestão de acessos a novos colaboradores.</li>
        <li>Configuração e padronização de computadores e servidores.</li>
        <li>Administração de usuários Azure, Vpn e Office 365.</li>
      </ul>
    </motion.div>
  </section>
);

export default Experience;
