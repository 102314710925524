import React from 'react';
import { motion } from 'framer-motion';

const Skills = () => (
  <section className="skills">
    <h3>SKILLS</h3>
  <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
    <ul>
      <li>Administração de sistemas Linux</li>
      <li>Elaboração de relatórios técnicos</li>
      <li>Hardware/Software | Office 365</li>
      <li>Monitoramento e segurança de redes</li>
      <li>Supervisão de colaboradores</li>
      <li>Gestão de vulnerabilidades</li>
      <li>Criação de processos de segurança</li>
      <li>Normas de Segurança</li>
      <li>Bash/Shell/Linux | Git</li>
      <li>SIEM | GLPI</li>
      <li>OSINT</li>
    </ul>
 </motion.div>
  </section>
);

export default Skills;
