import React from 'react';
import { motion } from 'framer-motion';

const Courses = () => (
  <section className="courses">
    <h3>CURSOS | CERTIFICAÇÕES</h3>
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <ul>
        <li>Desec Security – Novo Pentest Profissional - cursando</li>
        <li>Desec Security – Pentest Experience (DCPT) - cursando</li>
        <li>Desec Security – Pentest Mobile - cursando</li>
        <li>FIAP – Cybersecurity Hacker Skills – finalizado</li>
        <li>FIAP – Blockchain – finalizado</li>
        <li>FIAP – Gestão de Projetos – finalizado</li>
        <li>Desec Security – Introdução ao Pentest na Prática - finalizado</li>
        <li>Solyd Security – Introdução ao Hacking e Pentest - finalizado</li>
        <li>Udemy – Hardware e manutenção de computadores - finalizado</li>
        <li>Vero Academy – ISO 27001 / LGPD</li>
        <li>CISA - Cryptocurrency for Law Enforcement - finalizado</li>
        <li>CISA – Cloud Computing Security - finalizado</li>
        <li>CISA – Cyberessentials - finalizado</li>
        <li>CAA – Técnico Administrativo - finalizado</li>
        <li>Compway – Office, hardware, digitação e softwares - finalizado</li>
        <li>Cavalcante treinamentos – Linux - finalizado</li>
        <li>Curso em Vídeo – Redes - finalizado</li>
        <li>CNA – Inglês Essentials 1/2 - finalizado</li>
      </ul>
    </motion.div>
  </section>
);

export default Courses;
